import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import "./layout.scss";
import { gtmPageView } from './../../utils/utilities';
import TermsOfUseComp from "../termsofuse";

function TermsOfUseLayout({ children }) {

  useEffect(() => {
    gtmPageView()
  })

  const renderPageByPartner = () => {
        return (
          <>
            <Header />
            <TermsOfUseComp />
            <Footer />
          </>
        )
      }
  return (
    <div>
      {children}
      {renderPageByPartner()}
    </div>
  );
}

TermsOfUseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TermsOfUseLayout;
