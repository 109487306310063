import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import './termsofuse.scss';

const TermsOfUseComp = () => {
    const intl = useIntl();
    return (
        <>
        <div className='flex-container'>
            <h1 className='heading'>Terms of Service</h1>
            <a
                href={process.env.GATSBY_TOS}
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                {intl.formatMessage({ id: "tos1" })}
            </a>
            <a
                href={process.env.GATSBY_TOS}
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                {intl.formatMessage({ id: "tos2" })}
            </a>
            <a
                href={process.env.GATSBY_TOS}
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                {intl.formatMessage({ id: "tos3" })}
            </a>
            <a
                href={process.env.GATSBY_TOS}
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                {intl.formatMessage({ id: "tos4" })}
            </a>
            <a
                href={process.env.GATSBY_TOS}
                rel="noreferrer"
                target="_blank"
                className="link"
              >
                {intl.formatMessage({ id: "tos5" })}
            </a>
        </div>
        </>
    );
}

export default TermsOfUseComp;